import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { LandscapeMenu } from '../components/layout/LandscapeMenu/LandscapeMenu';
import { Page } from '../components/layout/Page';

import {
  setAppLanguage,
} from '../store/actions/helperActions/applicationActions';


const MainPage = () => {
  return (
    <Page>
      <LandscapeMenu />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
    }, dispatch),
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPage));
