import React from 'react';
import {connect} from 'react-redux';
import {I18nProvider} from '@lingui/react';

import catalogEn from '../../locales/en/messages.js';
import catalogDe from '../../locales/de/messages.js';

const catalogs = {en: catalogEn, de: catalogDe};

// TODO implement dynamic import

// Read more about internalization with lingui here:
// https://lingui.js.org/index.html
// https://lingui.js.org/tutorials/react.html

const LocalizeBoundary = (props) => {
  return (
    <I18nProvider language={props.language} catalogs={catalogs}>
      {props.children}
    </I18nProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

export default connect(
    mapStateToProps,
    null
)(LocalizeBoundary);
