import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import {bindActionCreators} from 'redux';

import {hideModal} from '../../../store/actions/helperActions/applicationActions';

const BaseModal = ({actions, modalProps}) => {
  const [isOpen, setOpened] = useState(false);

  useEffect(() => {
    setOpened(modalProps.open);
  }, [modalProps.open]);

  const afterOpenModal = () => {

  };

  const closeModal = () => {
    setOpened(false);
    modalProps.closeModal || actions.hideModal();
  };

  if (!modalProps.open) return null;

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel={modalProps.className}
        ariaHideApp={false}
      >
        {modalProps.children}
      </ReactModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.appState.modal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      hideModal,
    }, dispatch),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaseModal);
