import React from 'react';

import { Page } from '../../components/layout/Page';

const NotFoundPage = () => {
  return <Page className={'error'}>
    <h1>Page not found</h1>
  </Page>;
};

export default NotFoundPage;
