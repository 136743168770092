import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {
  setAppLanguage,
} from '../store/actions/helperActions/applicationActions';
import {Page} from '../components/layout/Page';
import classNames from "classnames";
import {isMobileOnly} from "react-device-detect";

import { news, newsToShow } from "./content/news";
import { NewsItem } from '../components/layout/NewsItem';

const HirekPage = () => {
  return (
    <Page>
      <div className={classNames('page-view', {mobile: isMobileOnly})}>
        <h1>Hírek</h1>
        {
          news.map((item, index) => {
            if (index >= newsToShow) return null;

            return <NewsItem content={item} />;
          })
        }
        {news.length === 0 && <div>Jelenleg nincs megjelenítendő hírünk</div>}
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
    }, dispatch),
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HirekPage));
