import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import Swipe from 'react-easy-swipe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { content } from './LandscapeMenuContent';
import { Trans } from '@lingui/react';

export const LandscapeMenu = () => {
  const [visibleItemIndex, setVisibleItemIndex] = useState(0);

  const backgroundSize = isMobileOnly ? `${content.length * 100}vw 100vh` : 'cover';
  const backgroundPosition = isMobileOnly ? `${100 / content.length * visibleItemIndex}% 0%` : 'unset';

  return <Swipe
    className={classNames('landscape-menu', { mobile: isMobileOnly })}
    style={{
      backgroundSize: backgroundSize,
      backgroundPosition: backgroundPosition
    }}
    onSwipeRight={() => (isMobileOnly && visibleItemIndex > 0) && setVisibleItemIndex(visibleItemIndex - 1)}
    onSwipeLeft={() => (isMobileOnly && visibleItemIndex < content.length - 1) && setVisibleItemIndex(visibleItemIndex + 1)}
  >
    {isMobileOnly && visibleItemIndex > 0 && <span className={'navigation-helper-icon left'}>
      <FontAwesomeIcon className={'icon'} icon={faChevronLeft} />
      <FontAwesomeIcon className={'icon'} icon={faChevronLeft} />
    </span>}
    {isMobileOnly && visibleItemIndex < content.length - 1 && <span className={'navigation-helper-icon right'}>
      <FontAwesomeIcon className={'icon'} icon={faChevronRight} />
      <FontAwesomeIcon className={'icon'} icon={faChevronRight} />
    </span>}
    {content.map((item, index) => (
      <NavLink key={item.id}
        to={item.url}
        exact={item.url === '/'}
        className={'landscape-menu-item'}
        style={{ display: (!isMobileOnly || index === visibleItemIndex) ? 'flex' : 'none' }}>
        {/*<Trans id={item.id}>{item.label}</Trans>*/}
        {item.label}
      </NavLink>))}
  </Swipe>;
};
