import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './reducers';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

const enhancer = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default function configureStore(preloadedState) {
  const store = createStore(
      createRootReducer(history),
      preloadedState,
      enhancer(applyMiddleware(
          routerMiddleware(history),
          thunk)
      ));

  return store;
}
