export const routes = {
  home: '/',
  hirek: '/hirek',
  rendeloink: '/rendeloink',
  szakrendeleseink: '/szakrendeleseink',
  munkatarsaink: '/munkatarsaink',
  dokumentumok: '/dokumentumok',
  allasok: '/allasok',
  rolunk: '/rolunk',
};
