import React from 'react';
import Icon from 'react-icons-kit';
import { phoneCall } from 'react-icons-kit/feather/phoneCall';
import classNames from 'classnames';

export const PhoneLink = ({phoneNumber, className}) => (
  <div data-testid={'phone-link-component'} className={classNames('phone-link', className)}>
    <a data-testid={'phone-link'} href={`tel:${phoneNumber}`}><Icon icon={phoneCall}/><span className={''}>Hívjon minket</span></a>
  </div>
);
