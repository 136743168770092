import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './style/style.scss';

import { Provider } from 'react-redux';
import { setStore } from './utils/helpers/reduxStoreHelper';
import ConnectedErrorBoundary, { ErrorBoundary } from './components/layout/ErrorBoundary';
import LocalizeBoundary from './components/layout/LocalizeBoundary';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store';

const store = configureStore();
setStore(store);

ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <LocalizeBoundary>
          <ConnectedErrorBoundary>
            <ConnectedRouter history={history}>
              <App/>
            </ConnectedRouter>
          </ConnectedErrorBoundary>
        </LocalizeBoundary>
      </Provider>
    </ErrorBoundary>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
