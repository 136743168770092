import React from 'react';
import {content} from './siteHeaderContent';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Trans} from '@lingui/react';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';

export const renderMenuItems = () => content.map((item) => (
  <NavLink key={item.id} id={item.url} to={item.url} exact={item.url === '/'} className={classNames({'mobile-nav-item' : isMobileOnly})} activeClassName="active-nav-item">
    <FontAwesomeIcon icon={item.icon} />
    {/*<Trans id={item.id}>{item.label}</Trans>*/}
    {item.label}
  </NavLink>
));
