import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {
  setAppLanguage,
} from '../store/actions/helperActions/applicationActions';
import {Page} from '../components/layout/Page';

const Munkatarsak = () => {
  return (
    <Page>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
    }, dispatch),
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Munkatarsak));
