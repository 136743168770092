import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import {MapImageContainer} from '../components/layout/MapImageContainer';
import {contacts as content} from './content/contacts';

import {Page} from '../components/layout/Page';

const Rendeloink = () => {
  return (
    <Page>
      <Scrollbars>
        <div className={'card-view'}>
          {content.map((item) => (<div key={item.name} className={classNames('contact-card', {'is-mobile': isMobileOnly})}>
            <div className={'title'}>{item.name}</div>
            <div className={'label'}>{item.address}</div>
            <div className={'label'}>{item.phone.join(', ')}</div>
            <div className={'label'}>{item.emails.join(', ')}</div>
            <div className={'map'}><MapImageContainer name={item.key}/></div>
            <div className={'open-time'}>
              <div className={'title small'}>Rendelési idő</div>
              {item.openHours.map(openTime => (
                  <div key={`${item.name}_${openTime.time}`}>
                    <div className={'label'}>{openTime.day}</div>
                    <div className={'label'}>{openTime.time}</div>
                    <div className={'label'}>{openTime.doctor}</div>
                  </div>
              ))}
            </div>
          </div>))}
        </div>
      </Scrollbars>
    </Page>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
    }, dispatch),
  };
};

export default (withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Rendeloink)));
