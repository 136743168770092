import React, {useState} from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import Dropdown from 'rc-dropdown';
import Menu, {Item} from 'rc-menu';

import {renderMenuItems} from './SiteHeaderCommon';

export const SiteHeaderDropdownMenu = () => {
  const [isOpened, setOpenedState] = useState(false);

  const menu = (
    <Menu
      className={'mobile-site-header-menu'}
      onClick={(e) => setOpenedState(!isOpened)}
    >
      {renderMenuItems().map((item) => (<Item key={item.key}>{item}</Item>))}
    </Menu>
  );

  return <Dropdown
    overlay={menu}
    trigger={['click']}
    animation={'slide-up'}
    onVisibleChange={() => setOpenedState(!isOpened)}
  >
    <div className={'mobile-site-header-menu-component'}>
      <HamburgerMenu
        isOpen={isOpened}
        menuClicked={() => {}}
        width={18}
        height={15}
        strokeWidth={1}
        rotate={0}
        color='black'
        borderRadius={0}
        animationDuration={0.3}
      />
    </div>
  </Dropdown>;
};
