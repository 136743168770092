import {routes} from '../../../pages/routes';

export const content = [
  {
    id: 'pageName.hirek',
    label: 'Aktualitások',
    url: routes.hirek,
  },
  {
    id: 'pageName.rendeloink',
    label: 'Rendelőink',
    url: routes.rendeloink,
  },
  {
    id: 'pageName.szakrendeleseink',
    label: 'Szakrendeléseink',
    url: routes.szakrendeleseink,
  },
  {
    id: 'pageName.munkatarsaink',
    label: 'Munkatársaink',
    url: routes.munkatarsaink,
  }
];
