import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {
  setAppLanguage,
} from '../store/actions/helperActions/applicationActions';
import {Page} from '../components/layout/Page';
import classNames from 'classnames';
import {isMobileOnly} from 'react-device-detect';
import {NavLink} from 'react-router-dom';
import {routes} from './routes';

const Szakrendelesek = () => {
  return (
    <Page>
      <div className={classNames('page-view', {mobile: isMobileOnly})}>
        <h1>Szakrendeléseink</h1>
        <ol type="I">
          <li className={'main'}>
            <h2>Foglalkozás-egészségügyi alapellátás</h2>
            <strong>Üzemorvostan szakorvosi szakképesítéssel rendelkező orvosaink és szintén szakképzett ápolóink az alapszolgáltatás keretében a következőket végzik:</strong>
            <ol>
              <li>
                <div>
                  Külön jogszabályban meghatározott [27/1995. (VII.25.) NM ] munkaköri alkalmassági vizsgálatok: munkába lépés előtt, munkakör változáskor, soron kívül (pl. hosszas táppénz vagy munkaképesség csökkenés megállapítása után), illetve az érvényesség lejártakor időszakosan és kilépés esetén (ún. záró-vizsgálat), melynek célja a <strong>munkavállaló terhelhetőségének</strong>, az <strong>adott munkakörre való egészségi alkalmasságának</strong> és <strong>foglalkoztathatóságának</strong>, esetleg szerzett <strong>egészségkárosodásának</strong> megállapítása.
                </div>
                <div>
                  <div>Az adat és anamnézis felvétel után a dolgozó fizikális vizsgálata következik, mely áll:</div>
                  <ul>
                    <li>vérnyomás-, pulzusmérésből</li>
                    <li>alkatvizsgálatból</li>
                    <li>„hallgatózásos” módszerrel a szívműködés és légzés megfigyelés</li>
                    <li>reflex-vizsgálatok</li>
                    <li>látótáblás látásvizsgálat</li>
                    <li>szűrő-audiometerrel történő hallásvizsgálat (munkakör függő)</li>
                    <li>záró-vizsgálatnál komplett laborvizsgálatra küldés (munkakör függő)</li>
                    <li>védőoltások beadása, mely munkakör függő és előzetes megbeszélés alapján történik,   de külön kérésre egyéb esetben is</li>
                  </ul>
                </div>
              </li>
              <li>A foglalkozási megbetegedések korai felismerése, fokozott expozíciós esetek kivizsgálása, bejelentése. (zajexpozíció, vegyi anyagok (rákkeltők), vibrációs munkakörök, képernyő előtt végzett munka)</li>
              <li>Munkavégzés egészségkárosító hatásainak vizsgálata.</li>
              <li>Krónikus betegségben szenvedők gondozása (állapotellenőrzés, gyógyszerek felírása) és akut megbetegedések ellátása.</li>
              <li>Munkakörülményekkel, védőfelszerelésekkel kapcsolatos tanácsadás.</li>
              <li>Az elsősegélynyújtás, a sürgős orvosi ellátás megszervezésének és a kijelölt elsősegélynyújtók szervezett oktatása, elméletben és gyakorlatban.</li>
              <li>Külön jogszabályban meghatározottak szerint az 1. fokú elbírálás alá tartozó közúti gépjárművezetők (hivatásos), nehézgépkezelői, építőgép-kezelői, hajózási és lőfegyvertartási (hivatásoshoz pszichológiai vélemény szükséges)egészségi alkalmassági vizsgálatok.</li>
              <li>Egészségnevelés.</li>
              <li>Igény, illetve állapot szerint helyileg végezhető 12 – elvezetéses (teljes) EKG-vizsgálat, a vizelet stix-szel történő analizálása, vércukor-mérés.</li>
            </ol>
          </li>
          <div>Vizsgálataink diszkrét és az <strong>orvosi titoktartás szabályainak betartásával</strong> történik.
            Az adatkezelés, mely írott és számítógépes formáját használjuk az erre elfogadott üzemorvosi programmal az előírásoknak megfelelően ellátott védelemmel alkalmazva.
          </div>
          <i>A munkavállalói egészségügyi törzskartonok kilépés után is eltárolásra kerülnek, 30 évig megőrzendő dokumentum, ezekről hivatalos megkeresés útján is csak másolat adható ki.</i>
          <div><strong className={'important'}>Vizsgálat ideje, optimális esetben, 10 - 15 perc.</strong></div>
          <div>A dolgozó vizsgálata után a munkaköri alkalmassági orvosi vélemény átadásra kerül, melyet jól olvashatóan, névvel, születési dátummal, munkakör megnevezéssel, esetleges korlátozások feltüntetésével, a vélemény érvényességével és dátummal látunk el.</div>
          <div className={'important'}>A fenti vizsgálatok valamelyikére való küldéshez az alábbiakat kérjük szépen:</div>
          <ol>
            <li>
              Nyomtatvány:
              <ul>
                <li>
                  <strong>Beutalás munkaköri alkalmassági vizsgálatra (A.3510-217)</strong> Ennek értelemszerű kitöltése (dolgozó neve, munkaköre, kockázati tényezők) nagyon fontos. Ez alapján történik a további, kiegészítő vizsgálatok, illetve a gyakoriság meghatározása, mely a munkahelyi vezető aláírásával és bélyegzőjével ellátva érvényes !
                </li>
                <li>
                  <strong>Egészségügyi nyílatkozat és vizsgálati adatok (C.3151-2/a)</strong> (eü-könyves munkakörök esetén)
                </li>
              </ul>
            </li>
            <li>1 évnél nem régebbi, <strong>negatív tüdőszűrő-lelet</strong></li>
            <li>Egyedi esetekben (rákkeltő munkakörök, munkahelyeknél) <strong>3 hónapon belüli vér-vizelet eredmény</strong></li>
            <li>
              <div><strong>Gépjármű-alkalmassági vizsgálat:</strong> minden esetben a jogosítvány és a személyi igazolvány.</div>
              <div>
                Hivatásos (2.csop.) gépjárművezetőktől:
                <ul>
                  <li>háziorvosi igazolás meglévő krónikus betegségekről, ha nincs arról is</li>
                  <li>EKG- lelet</li>
                  <li>Szemészeti lelet</li>
                  <li>Vizelet lelet  (utóbbiakhoz szintén háziorvosi beutalás szükséges)</li>
                  <li>Amennyiben munkakör betöltéséhez szükséges, akkor a fent említett nyomtatvány kitöltése (építőgép-kezelői jogosítványok érvényesítésnél is ugyanígy), munkakört megjelölve.</li>
                </ul>
              </div>
            </li>
          </ol>
          <div>Területi bejárásaink alkalmával tett észrevételekről, az ellátott dolgozókról összefoglaló, ún. <strong>Munkahigiénés jegyzőkönyv készül két példányban.</strong></div>
          <div><strong>Vállaljuk a munkahelyi elsősegélynyújtók</strong> elméleti és gyakorlati képzését mind a munkáltató telephelyén, (előre egyeztetett időben), mind a rendelőnkben törtéfolyamatosan szervezzük.</div>
          <div>
            <div><strong>Munkavédelmi (munkabiztonsági)</strong> munkatársainkkal közreműködve végezzük el az alábbi feladatokat:</div>
            <ol>
              <li>Munkahelyi veszélyforrások feltárása.</li>
              <li>Kockázatbecslések elvégzése.</li>
              <li>Foglalkozás-egészségügyi, -fiziológiai, -ergonómiai, -higiénés feladatok megoldása.</li>
              <li>A munkáltató katasztrófa-megelőző, -elhárító, -felszámoló, és az előidézett károsodások rehabilitációs tervének kidolgozása.</li>
            </ol>
          </div>
        </ol>
        <NavLink to={routes.rendeloink}><h1>Vizsgálatok igénybevétele előtt kérjük érdeklődjön munkatársainknál vagy e-mail-elérhetőségeinken!</h1></NavLink>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
    }, dispatch),
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Szakrendelesek));
