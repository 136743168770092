import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';

import {
  setAppLanguage,
} from '../store/actions/helperActions/applicationActions';
import {Page} from '../components/layout/Page';

const Rolunk = () => {
  return (
    <Page>
      <div className={classNames('page-view', {mobile: isMobileOnly})}>
        <h1>Rólunk</h1>
        <div><strong>A Pro-Med Egészségügyi és Szociális Szolgáltató Betéti Társaság</strong> 1995-ben alakult járó betegek alap – és szakellátásának biztosítására.</div>
        <strong>Tevékenységi körünkbe tartozik:</strong>
        <ol>
          <li>Foglalkozás-egészségügyi alapellátás</li>
          <li>A foglalkozás-egészségügyi alapellátás keretében több mint 300 céggel állunk szerződéses kapcsolatban, s közel 11.000 fő munkavállaló ellátását biztosítjuk.</li>
          <li>Munkahelyi elsősegély-nyújtó képzés</li>
          <li>Belgyógyászati szakellátás</li>
          <li>24 órás háziorvosi ügyeletek biztosítása,  jelenleg Százhalombatta városában 24 órás háziorvosi ügyeletet működtetünk, 2005. óta folyamatosan</li>
          <li>Szűrővizsgálatok szervezése, biztosítása</li>
          <li>Betegszállítás, jelenleg  Százhalombatta Város érintett lakossága (mintegy 18.000 fő) részére az egészségügyi intézménybe történő beteg szállítási feladatainak ellátása.</li>
          <li>Rendezvények, sportesemények egészségügyi biztosítása</li>
        </ol>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
    }, dispatch),
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Rolunk));
