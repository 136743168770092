import {APP_ACTIONS} from '../../actionTypes';

export const setDocumentLoaded = () => {
  return {
    type: APP_ACTIONS.SET_DOCUMENT_LOADED,
  };
};

export const showModal = (modalProps, modalType) => {
  return {
    type: APP_ACTIONS.SHOW_MODAL,
    modalProps,
    modalType,
  };
};

export const hideModal = () => {
  return {
    type: APP_ACTIONS.HIDE_MODAL,
  };
};

export const setAppLanguage = (language) => {
  return {
    type: APP_ACTIONS.SET_APP_LANGUAGE,
    payload: language,
  };
};
