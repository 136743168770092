import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import NotFoundPage from './pages/helperPages/NotFoundPage';
import MainPage from './pages/MainPage';
import Rendeloink from './pages/Rendeloink';
import Szakrendelesek from './pages/Szakrendelesek';
import Munkatarsak from './pages/Munkatarsak';
import Hirek from './pages/HirekPage';
import Rolunk from './pages/Rolunk';

import SiteHeader from "./components/layout/SiteHeader/SiteHeader";
import BaseModal from './components/layout/modals/BaseModal';
import HirekModal from './components/layout/modals/HirekModal';

import {routes} from './pages/routes';

import {
  setDocumentLoaded
} from './store/actions/helperActions/applicationActions';

const App = () => {
  return (
    <div className={classNames('app')}>
      <SiteHeader />
      <div className="app-content">
        <Switch>
          <Route exact path={routes.home} component={MainPage}/>
          <Route exact path={routes.hirek} component={Hirek}/>
          <Route path={routes.rendeloink} component={Rendeloink}/>
          <Route path={routes.szakrendeleseink} component={Szakrendelesek}/>
          <Route path={routes.munkatarsaink} component={Munkatarsak}/>

          <Route path={routes.rolunk} component={Rolunk}/>
          <Route component={NotFoundPage}/>
        </Switch>
      </div>
      <HirekModal />
      <BaseModal />
    </div>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setDocumentLoaded,
    }, dispatch)
  };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
