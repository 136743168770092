export const newsToShow = 3;

export const news = [
  //{
  //  date: '2019-11-04',
  //  validFrom: '2019-11-10',
  //  validTo: '2019-11-20',
  //  title: 'Example news',
  //  description: 'Example news'
  //}
];
