import React from 'react';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { push } from 'connected-react-router';
import {isMobileOnly} from 'react-device-detect';
import Select from 'react-select';

import {isMultilingual} from '../../../config';
import {setAppLanguage} from '../../../store/actions/helperActions/applicationActions';
import {languageOptions} from '../../../locales/localConstants';

import {SiteHeaderDropdownMenu} from './SiteHeaderDropdownMenu';
import {renderMenuItems} from './SiteHeaderCommon';
import {Logo} from '../Logo';
import {PhoneLink} from '../PhoneLink';

const customStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#B2D4FF' : 'transparent',
    }
  }
};

let SiteHeader = ({actions, language}) => {
  return <header className={classNames('site-header')}>
    {!isMobileOnly && <div className={'links'}>
      {renderMenuItems()}
    </div>}
    {isMultilingual && <div className={'dropdown primary-dropdown-colors language-selector'}>
      <Select
        value={language}
        onChange={(option) => actions.setAppLanguage(option.value)}
        options={languageOptions}
        isSearchable={false}
        placeholder={language}
        styles={customStyles}
      />
    </div>}
    {isMobileOnly && <SiteHeaderDropdownMenu />}
    <Logo onClick={() => actions.push('/')} className={classNames({'is-mobile': isMobileOnly})} />
    <PhoneLink phoneNumber={'+36-30-229-40-89'} className={classNames({'is-mobile': isMobileOnly})} />
  </header>;
};

SiteHeader = withRouter(SiteHeader);

const mapStateToProps = (state) => ({
  language: state.appState.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setAppLanguage,
      push
    }, dispatch),
  };
};

SiteHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteHeader);

export default SiteHeader;
