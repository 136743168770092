import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {isMobileOnly} from "react-device-detect";
import Scrollbars from 'react-custom-scrollbars';

import {showModal, hideModal} from '../../../store/actions/helperActions/applicationActions';

import {news} from '../../../pages/content/news';
import {NewsItem} from "../NewsItem";

const HirekModal = ({actions}) => {
  const title = 'Aktuális hírek';

  const message = '';

  useEffect(() => {
    const actualNews = getNews();

    actualNews.length && openModal(actualNews);
  }, []);

  const getNews = () => {
    const today = moment().endOf('day');
    const retVal = [];

    news.forEach((item) => {
      if (today.isBetween(moment(item.validFrom).add(-1, 'weeks'), moment(item.validTo), 'day')) retVal.push(item);
    });

    return retVal;
  };

  const cancelCloseModal = () => {
    actions.hideModal();
  };

  const renderContent = (actualNews) => {
    return <div className="modal-content modal-news-content">
      <h2>{title}</h2>
      <Scrollbars>
        {actualNews.map((item, index) => (<NewsItem key={`actual_news_${index}`} content={item}/>))}
      </Scrollbars>
      <button onClick={cancelCloseModal}>Bezár</button>
    </div>;
  };

  const openModal = (actualNews) => {
    actions.showModal({
      open: true,
      title: title,
      message: '',
      closeModal: cancelCloseModal,
      children: renderContent(actualNews),
      className: isMobileOnly ? 'modal-news-mobile' : 'modal-news',
    }, 'confirm');
  };

  return null;
};

HirekModal.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  classNames: PropTypes.string,
  onConfirm: PropTypes.func,
  placeholder: PropTypes.node,
};

const mapStateToProps = (state) => ({
  ...state.appState.modal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      showModal,
      hideModal,
    }, dispatch),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HirekModal);
