export const contacts = [
  {
    name: 'Érd',
    key: 'erd',
    position: {lat: 47.3793828, lng: 18.9301175},
    address: '2030 Érd, Budai u. 20. 1/3 Korall-Ház I. (Takarékszövetkezet felett)',
    phone: ['+36/30-229-40-89, +36/30-952-54-61'],
    emails: ["csaktimi@t-online.hu", "promedfogleu@gmail.com"],
    openHours: [
      {
        day: "Kedd",
        time: "08:00-10:00",
        doctor: "Dr. Bereczk Gizella"
      },
      {
        day: "Csütörtök",
        time: "08:00-10:00",
        doctor: "Dr. Bereczk Gizella"
      },
      {
        day: "Péntek",
        time: "08:00-10:00",
        doctor: "Dr. Bereczk Gizella"
      }
    ]
  },
  {
    name: 'Százhalombatta',
    key: 'szazhalombatta',
    position: {lat: 47.3213097, lng: 18.9091478},
    address: '2440 Százhalombatta, Gesztenyés u. 10.  fszt. D14. (Laboratóriummal szemben)',
    phone: ['+36/30-229-40-89, +36/30-952-54-61'],
    emails: ["csaktimi@t-online.hu", "promedfogleu@gmail.com"],
    openHours: [
      {
        day: "Hétfő",
        time: "14:00-16:00",
        doctor: "Dr. Tóth Béla"
      },
      {
        day: "Kedd",
        time: "11:30-13:30",
        doctor: "Dr. Farkas Gábor"
      },
      {
        day: "Csütörtök",
        time: "11:30-15:30",
        doctor: "Dr. Bereczk Gizella"
      }
    ]
  }
];
