import {faLightbulb, faHome, faCity, faUserFriends, faNewspaper, faStethoscope} from '@fortawesome/free-solid-svg-icons';
import {routes} from '../../../pages/routes';

export const content = [
  {
    id: 'pageName.home',
    label: 'Főoldal',
    url: routes.home,
    icon: faHome,
  },
  {
    id: 'pageName.hirek',
    label: 'Hírek',
    url: routes.hirek,
    icon: faNewspaper,
  },
  {
    id: 'pageName.rendeloink',
    label: 'Rendelőink',
    url: routes.rendeloink,
    icon: faCity,
  },
  {
    id: 'pageName.szakrendeleseink',
    label: 'Szakrendeléseink',
    url: routes.szakrendeleseink,
    icon: faStethoscope,
  },
  // {
  //   id: 'pageName.munkatarsaink',
  //   label: 'Munkatársaink',
  //   url: routes.munkatarsaink,
  //   icon: faUserFriends,
  // },
  {
    id: 'pageName.rolunk',
    label: 'Rólunk',
    url: routes.rolunk,
    icon: faLightbulb,
  },
];
