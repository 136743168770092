import {element, object} from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/react';

// import UserRequests from '../../middleware/UserRequests';

// TODO: rewrite it with hook
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }


  componentDidCatch(error, info) {
    this.setState({hasError: true});

    // const payload = {
    //   componentStackTrace: info ? info.componentStack : null,
    //   jsErrorStackTrace: error ? JSON.stringify({ message: error.message, stack: error.stack }) : null,
    //   store: this.props.state ? JSON.stringify(this.props.state) : null,
    //   url: window.location.href
    // };
    //
    // UserRequests.sendErrorLog(payload).then(() => {
    //
    // }).catch(() => {
    //
    // });
  }

  handleGoingBackClick() {
    window.history.back();

    const sleep = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    sleep.then(() => window.location.reload());
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={classNames('app')}>
          <div className="app-content">
            <div className={classNames('header')}>
              <div className="logo" />
            </div>
            <div className="error-component">
              <h2 className="error-component__title">
                <Trans id="errorBoundary.header">Something went wrong</Trans>
              </h2>
              <p className="error-component__message">
                <Trans id="errorBoundary.message">
                    This can be due to wrong or inconsistent data or a temporary server error. Such errors
                    can occur unexpectedly in data-driven solutions. Your data and your work was not lost
                    and you can try continuing your work by clicking on Refresh below.
                </Trans>
              </p>
              <span className="action-button secondary small" onClick={() => window.location.reload()}><Trans id="errorBoundary.refresh">Refresh</Trans></span>
              <p className="error-component__message">
                <Trans id="errorBoundary.navigateBack">
                    If you're still seeing this page after refreshing, try
                  <span className="navigate-back" onClick={this.handleGoingBackClick}> going back to the previous page.</span>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  state: object,
};


const mapStateToProps = (state) => ({
  state,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorBoundary);
