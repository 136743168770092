import {APP_ACTIONS} from '../../actionTypes';

const initialState = {
  language: localStorage.getItem('app-language') || 'en',
  modal: {
    modalType: null,
    modalProps: {},
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_ACTIONS.SET_DOCUMENT_LOADED: {
      return {
        ...state,
        isDocumentLoaded: true,
      };
    }
    case APP_ACTIONS.SHOW_MODAL:
      return {
        ...state,
        modal: {
          modalProps: action.modalProps,
          modalType: action.modalType,
          type: action.type,
        },
      };
    case APP_ACTIONS.HIDE_MODAL:
      return {
        ...state,
        modal: initialState.modal,
      };
    case APP_ACTIONS.SET_APP_LANGUAGE:
      localStorage.setItem('app-language', action.payload);

      return {
        ...state,
        language: action.payload,
      };
    case APP_ACTIONS.LOGOUT: {
      return {
        ...initialState,
        isDocumentLoaded: state.isDocumentLoaded,
      };
    }
    default:
      return state;
  }
}
