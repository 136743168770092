import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStarOfLife} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export const Logo = ({onClick, className}) => (
  <div className={classNames('app-logo', className)} onClick={onClick}>
    <FontAwesomeIcon icon={faStarOfLife} />
    <label>Pro-Med</label>
  </div>
);
